import basePageFields from '@fragments/pages/basePageFields';

import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';

import SubnavFragment from '@fragments/shared/SubnavFragment';
import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment EventCollectionFragment on EventCollection {
        ${basePageFields}
        id
        title
        dek
        _collection

        contributors {
            ...PersonSummaryFragment
        }

        parent {
            relationTo
            value {
                ... on EventsLanding {
                    subNav {
                        ...SubnavFragment
                    }
                }
            }
        }

        streamTiered {
            ...StreamTieredFragment
        }
        seo {
            ...SeoFragment
        }
    }

    ${PersonSummaryFragment}
    ${SubnavFragment}
    ${StreamTieredFragment}
    ${SeoFragment}
`;
